import React from 'react';
import { Link } from 'react-router-dom';
import SheWantsMeToNft from '../assets/SheWantsMeToNftCover.png';
import HowToChooseAnOnlineTradingPlatformLikeAPro from '../assets/Cover Page How To Choose An Online Trading Platform.png'
import AudAVictimOfABadGame from '../assets/AUD-Victim-Of-A-Bad-Game.png';

function SamSamieBooks() {
  return (
    <div className="flex w-full border-none mt-5 mb-4 justify-center items-center">
      <div className='grid md:grid-cols-3'>






    <div className='flex justify-center items-center md:p-2 p-[25px] animate-rise-l-to-r md:mx-5'>
        <div className="max-w-sm p-7 bg-gradient-to-tr from-[#110011] to-[#0d0525] border border-gray-500 rounded-3xl shadow hover:bg-gradient-to-t hover:from-[#2d002d] hover:to-[#502fb4]">
         
            <br />
            
    <div className='flex justify-center'>
        <img src={SheWantsMeToNft} alt="She Wants Me To NFT Book By Sam Samie" className='flex justify-center items-center w-2/3'/>
    </div>

    <div>
        <h3 className='text justify-center text-center mx-0 my-5 text-1xl font-normal text-gray-300'>
            A Journey into the Social and Technological Revolution of Non-Fungible Tokens (NFTs)
        </h3>
    </div>

    
            
            
    <Link to="https://books.google.com.au/books/about/She_Wants_Me_To_NFT.html?id=adUEEQAAQBAJ&redir_esc=y" target='_blank' rel='noopener noreferrer'>
        <div className="flex w-auto justify-center items-center px-3 py-2 text-sm mt-12 mx-[25%] font-medium text-center text-white border-gray-400 border 
            bg-gradient-to-tr from-[#000000] to-[#291348] 
            hover:bg-gradient-to-tr hover:from-[#291348] hover:to-[#000000] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    GET FOR FREE
        </div>
    </Link>
        </div>
    </div>





    <div className='flex justify-center items-center md:p-2 p-[25px] animate-rise md:mx-5'>
        <div className="max-w-sm p-7 bg-gradient-to-tr from-[#110011] to-[#0d0525] border border-gray-500 rounded-3xl shadow hover:bg-gradient-to-t hover:from-[#2d002d] hover:to-[#502fb4]">
         
            <br />
            
    <div className='flex justify-center'>
        <img src={HowToChooseAnOnlineTradingPlatformLikeAPro} alt="How To Choose An Online Trading Platform Like A Pro" className='flex justify-center items-center w-2/3'/>
    </div>

    <div>
        <h3 className='text justify-center text-center mx-0 my-5 text-1xl font-normal text-gray-300'>
        A guide to help you smartly choose the right trading platform for your needs.        </h3>
    </div>

    
            
            
    <Link to="https://www.amazon.com.au/Choose-Online-Trading-Platform-Like-ebook/dp/B083499N9L" target='_blank' rel='noopener noreferrer'>
    <div className="flex w-auto justify-center items-center px-3 py-2 text-sm mt-12 mx-[25%] font-medium text-center text-white border-gray-400 border 
            bg-gradient-to-tr from-[#000000] to-[#291348] 
            hover:bg-gradient-to-tr hover:from-[#291348] hover:to-[#000000] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    GET FOR FREE
        </div>
    </Link>
        </div>
    </div>






    <div className='flex justify-center items-center md:p-2 p-[25px] animate-rise-r-to-l md:mx-5'>
        <div className="max-w-sm p-7 bg-gradient-to-tr from-[#110011] to-[#0d0525] border border-gray-500 rounded-3xl shadow hover:bg-gradient-to-t hover:from-[#2d002d] hover:to-[#502fb4]">
         
            <br />
            
    <div className='flex justify-center'>
        <img src={AudAVictimOfABadGame} alt="AUD A Victim Of A Bad Game" className='flex justify-center items-center w-2/3'/>
    </div>

    <div>
        <h3 className='text justify-center text-center mx-0 my-5 text-1xl font-normal text-gray-300'>
        A critical look at how the Royal Commission and RBA policies impact Australia's economy.        </h3>
    </div>

    
            
            
    <Link to="https://www.academia.edu/41908205/AUD_Victim_Of_A_Bad_Game_By_Sam_Samie" target='_blank' rel='noopener noreferrer'>
    <div className="flex w-auto justify-center items-center px-3 py-2 text-sm mt-12 mx-[25%] font-medium text-center text-white border-gray-400 border 
            bg-gradient-to-tr from-[#000000] to-[#291348] 
            hover:bg-gradient-to-tr hover:from-[#291348] hover:to-[#000000] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    GET FOR FREE
        </div>
    </Link>
        </div>
    </div>







     
      </div>
    </div>
  );
}

export default SamSamieBooks;
