// src/components/Statistic.jsx
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const Statistic = ({ start = 0, end, label }) => {
  const [count, setCount] = useState(start);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (!inView) return;

    let startValue = start;
    const duration = 150; // Duration of the animation in milliseconds
    const stepTime = Math.abs(Math.floor(duration / (end - start)));
    
    const increment = () => {
      startValue += Math.ceil((end - startValue) / 150);
      setCount(startValue);

      if (startValue < end) {
        setTimeout(increment, stepTime);
      } else {
        setCount(end);
      }
    };

    increment();
  }, [inView, start, end]);

  return (
    <div ref={ref} className="text-center">
        
       
      <h2 className="text-5xl font-bold font-ebgaramond text-transparent bg-clip-text bg-gradient-to-r from-[#130fff] to-[#07fd0f]">{count.toLocaleString()}+</h2>

      <p className="text-xl text-gray-300">{label}</p>

    </div>
  );
};

export default Statistic;
