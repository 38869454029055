import React from 'react';
import SamSamieImageSrc from '../assets/SamSamieImage.gif'; 

function SamSamieImageComponent() { 
  return (
    <div className='flex md:hidden justify-center items-center mx-8 my-5'>
        <img src={SamSamieImageSrc} alt='Sam Samie'></img>
    </div>
  )
}

export default SamSamieImageComponent;
