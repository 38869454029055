// ./components/About.js

import React from 'react';
import Logo from '../assets/logo512.png'

const Beta = () => {
  return (
    <div className='justify-center items-center pr-[50px] pl-[50px] my-96'>
    <div className='flex items-center justify-center mt-[100px] '>
        <img className='flex w-[200px] h-[200px] items-center justify-center animate-spin' src={Logo} alt='Sam Samie Project'></img>
    </div>
    <div className='flex justify-center items-center '>










    <p className='text-[#00df9a] items-center justify-center text-5xl font-bold p-10 '>
            COMING SOON...
        </p>
    </div>

    
</div>

  );
}

export default Beta;