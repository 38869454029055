import React from 'react';
import { ReactTyped } from "react-typed";
import { Link } from 'react-router-dom';
import '../css/MainerCss.css';


function Mainer() {
  return (
    <div className="p-3 md:mx-24 mx-3 border-2 border-[#3f1544] rounded-[45px] overflow-hidden md:grid md:grid-cols-2 bg-gradient-to-r from-purple-800 via-purple-900 to-black text-white mainer-background">
      
    <div className="md:ml-32 md:my-40">

    <div className="md:text-8xl text-5xl font-bold md:mt-0 mt-7 md:mb-0 mb-10 text-[#4bebfd] items-center flex justify-center md:justify-start">
      <h1>Lets create</h1>
    </div>

        <div className='md:flex p-0 pl-1'>
          
          <p className='md:text-3xl text-xl font-bold p-0 md:mr-5'>
           Let's talk about
          </p>
          
          
          <ReactTyped
          className='md:text-3xl sm:text-3xl text-xl md:pl-0 pl-0 font-light'
            strings={[
            'Artificial Intelligence',
            'Information Technology',
            'Blockchain',
            'Investment',
            'NFTs',
            'Trading',
            'Bitcoin',
            'Marketing',
            'Programming',
            'Enterprise',
            'Future',
            ]}
            typeSpeed={50}
            backSpeed={30}
            autoInsertCss
            fadeOutDelay={50}
            showCursor
            loop
          />
        </div>


        <button className="md:flex hidden md:mt-10 mt- md:mb-1 bg-gradient-to-r from-blue-600 to-purple-500 md:text-lg text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors justify-left ">
          <Link to='https://docs.google.com/forms/d/1apr7iVPrfZBkHClt_emz9BvhUNo74EuztJBZxt9wP7c/viewform?edit_requested=true' rel='noopener noreferrer' target='_blank'>Contact Sam Samie</Link>
        </button>


        <p className="text-[12px] mt-0 text-[#c6c4c4] md:flex hidden">* Google account required</p>
      </div>





      <div className="flex my-0 md:p-20 items-center justify-right md:mt-'' mt-2" >
        <video className="shadow-lg w-full border-2 rounded-[45px] overflow-hidden border-[#112d39]" autoPlay loop muted>
          <source src="/Sam_Samie_official_website_baner_video_loop.mp4" type="video/mp4" /> {/* Referencing from public directory */}
          Your browser does not support the video tag.
        </video>
      </div>


<div className='flex md:hidden items-center justify-center mt-24'>
      <button className="bg-gradient-to-r from-blue-600 to-purple-500 text-white font-semibold py-2 px-4 mb-5 rounded-lg hover:bg-blue-700 transition-colors">
          <Link to='https://docs.google.com/forms/d/1apr7iVPrfZBkHClt_emz9BvhUNo74EuztJBZxt9wP7c/viewform?edit_requested=true' rel='noopener noreferrer' target='_blank'>Contact Sam Samie</Link>
        </button>
</div>


    </div>
  );
}

export default Mainer;
