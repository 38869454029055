import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Title from './components/Title';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Beta from './components/Beta';
// import Documentation from './components/Documentation';
import CodeMonitorPlugin from './components/Codemonitorplugin';
import Cards1 from './components/Cards1.js';
import SmartContractAuditor from './components/SmartContractAuditor';
// import Singleimage from './components/Singleimage';
import Cards2 from './components/Cards2.js';
import SamSamieWiki from './components/SamSamieWiki.js';
import SamSamieBooks from './components/SamSamieBooks.js';
import Gradient1 from './components/Gradient1.js';
import Gradient2 from './components/Gradient2.js';
import AttackMap from './components/AttackMap.js';
// import PriceBanner from './components/PriceBanner.js';
import Apis from './components/Apis.js';
import Statistics from './components/Statistics.jsx';
import Avatar from './components/Avatar.js';
import Mainer from './components/Mainer.jsx';
import SamSamieImage from './components/SamSamieImage.js';
import FutureOfTechAndTrendsBySamSamie from "./components/FutureOfTechAndTrendsBySamSamie.js"

function App() {
  return (
    <Router>
      <div>
        <Navbar />
         <Routes>
          <Route path="/Beta" element={<Beta />} /> {/* This will only show when the URL path is '/about' */}
          <Route path='/Apis' element={<Apis />} />
          <Route path="/Avatar" element={<Avatar />} />
          {/* <Route path="/Documentation" element={<Documentation />} /> This will only show when the URL path is '/Documentation' */}
          <Route path="/" element={<> {/* Wrap existing components in an empty fragment */}
          
            {/* <PriceBanner /> */}
            
            <Mainer />
            <Statistics />
            <Title />
            <SamSamieBooks />
            <FutureOfTechAndTrendsBySamSamie />
            <SamSamieImage />
            <SamSamieWiki />
            <CodeMonitorPlugin />
            <AttackMap />
            <SmartContractAuditor />
            <Gradient2 />
            {/* <Singleimage /> */}
            <Cards1 />
            <Cards2 />
            <Gradient1 />
            <Newsletter />
         
            
            
          </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
