import React from 'react';
import GoogleBooksPartner from '../assets/GoogleBooksPartner.png';
import { Link } from 'react-router-dom';
import '../css/Cards1.css';


function Cards2() {
  return (
    <div className="screen w-full justify-center items-center border-none pt-[100px] pb-[0px] mt-[300px] bg-gradient-to-b from-[#000000] to-[#0b073f] ">
      <div className='grid md:grid-cols-2 '>
      <div className='flex justify-center items-center '>
      <div className="flex h-[300px] max-w-lg p-7 border-none">
      <img src={GoogleBooksPartner} alt='Google Books Partner'></img>
            
      </div>
      </div>






        
        <div className='flex justify-center items-center py-[100px]'>
          <div className="md:h-[375px] h-[500px] mx-5 max-w-lg p-7 bg-none border border-gray-400 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-100">Google Books Partner</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            Sam Samie is a Registered Google Partner (Publisher), offering complimentary book publishing services. If you've authored a book that you believe will benefit readers, please get in touch for a review.
            <br />
            <br />
            *This service is currently free of charge. Approval is required, and submission does not guarantee publication.
            </p>
            <br />
            

            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdNFDXXK3_47MMgedEh1M_o1yQo7Ta0zsBNzgCX641zjw7VOg/viewform" target='_blank' rel='noopener noreferrer'>
        <div className="flex w-auto justify-center items-center px-3 py-2 text-sm mt-2 mx-[25%] font-medium text-center text-white border-gray-400 border 
            bg-gradient-to-tr from-[#000000] to-[#291348] 
            hover:bg-gradient-to-tr hover:from-[#291348] hover:to-[#000000] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    Get in touch
        </div>
        </Link>


            
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Cards2;
