import React from 'react';
import CodeMonitorLogoPhoneGirl from '../assets/CodeMonitorLogoPhoneGirl.png';
import { Link } from 'react-router-dom';

function CodeMonitorPlugin() {
  return (
    <div id='projects' className="relative screen w-full justify-center items-center border-none animate-rise md:my-[200px] md:py-[100px] bg-gradient-to-t from-[#38380c] to-[#000000] mb-20 md:mb-32">
      {/* Gradient Overlays */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 right-0 h-20 bg-gradient-to-b from-black to-transparent"></div>
        <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black to-transparent"></div>
      </div>

      <div className='grid md:grid-cols-2 relative'>
        
        <div className='flex justify-center items-center md:pr-40 md:pl-35 md:p-[75px]'>
          <img className='md:p-[30px] py-20 ' src={CodeMonitorLogoPhoneGirl} alt="Markets" />
        </div>


        <div className='flex justify-center items-center md:p-4 p-5'>
          <div className="max-w-lg p-7 bg-gradient-to-r from-[#093824] to-[#012f3a] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="text-4xl font-bold tracking-tight text-[#4cf2ef]">Code Monitor WP Plugin</h2>
            <br />
            <h2 className="text-2xl font-bold tracking-tight text-gray-200">Why did I make it?</h2>
            
            <p className="text-1xl mb-3 font-normal text-gray-200">
            I was looking for a similar plugin but there was nothing out there. So I decided to make one. Then I said to myself why shouldn't I share it with other WordPress administrators to help them secure their websites.
            <br />
            <br />
            I made this WordPress Plugin and made it available on the WordPress Plugin Store for FREE. Code Monitor is an enhanced, user-friendly plugin that scrutinizes your WordPress directories for new files based on specified types (e.g., PHP, HTML, JS) and changes in the .htaccess file. It includes subdirectory monitoring and alerts you via email when new files are detected or if there are modifications to the .htaccess file.
            <br />
            
            </p>
            <br />
            <Link to="https://wordpress.org/plugins/code-monitor/#description" target='_blank' rel='noopener noreferrer'>
        <div className="flex w-auto justify-center items-center px-3 py-3 text-sm mb-7 mx-[25%] font-medium text-center text-white 
            bg-gradient-to-tr from-[#3b0b9c] to-[#5c309a] 
            hover:bg-gradient-to-tr hover:from-[#5609c2] hover:to-[#3b0b9c] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    Learn more
        </div>
    </Link>
          </div>
        </div>


      </div>
    </div>
  );
}

export default CodeMonitorPlugin;
