import React, { useState, useEffect, useCallback } from 'react';

function Apis() {
  // State to store fetched data, loading state, and error state
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // API keys from environment variables
  const ApiKeyA = process.env.REACT_APP_API_KEY_A;
  console.log('ApiKeyA:', ApiKeyA); // Should log 666

  const ApiKeyB = process.env.REACT_APP_API_KEY_B;
  console.log('ApiKeyB:', ApiKeyB); // Should log the URL

  // Function declarations and expressions
  function Send() { alert("You called Send Function declaration"); }
  const Send2 = function() { alert("You called Send2 Function expression"); }
  const Send3 = () => { alert("You called Send3 Arrow function"); }

  const Send4 = () => { alert(`Your ApiKeyA is ${ApiKeyA}`); }
  const Send5 = () => { alert(`Your ApiKeyB is ${ApiKeyB}`); }

  // Fetch data from API
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(ApiKeyB);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [ApiKeyB]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to display fetched data
  const DisplayData = () => {
    alert(`Fetched Data: ${JSON.stringify(data)}`);
  };

  return (
    <div className='justify-center items-center h-auto w-auto grid grid-cols-3 rows-3'>
      <div className='flex justify-center items-center border-yellow-200 border-2 text-yellow-200 p-3'>
      <div className='flex justify-center items-center border-2 md:w-[50%] text-yellow-200 p-3'>
        <button onClick={Send}>Call Send Function declaration</button>
        </div>
      </div>
      <div className='flex justify-center items-center border-yellow-200 border-2 text-yellow-200 p-3'>
      <div className='flex justify-center items-center border-2 md:w-[50%] text-yellow-200 p-3'>
        <button onClick={Send2}>Call Send2 Function expression</button>
        </div>
      </div>
      <div className='flex justify-center items-center border-yellow-200 border-2 text-yellow-200 p-3'>
      <div className='flex justify-center items-center border-2 md:w-[50%] text-yellow-200 p-3'>
        <button onClick={Send3}>Call Send3 Arrow function</button>
        </div>
      </div>
      <div className='justify-center items-center text-yellow-200 border-2 border-yellow-200 p-3'>
        <div className='flex justify-center items-center'>
        </div>
        <div className='flex justify-center items-center border-none text-yellow-200 p-3'>
          <button onClick={Send4} className='flex border-white p-3 border-2 justify-center items-center'>Get Api Key A</button>
        </div>
      </div>
      <div className='grid justify-center items-center text-yellow-200 border-2 border-yellow-200 p-3'>
        <div className='flex justify-center items-center p-3'>
          <button className='flex border-white border-2 justify-center items-center p-3' onClick={Send5}>Get Api Key B</button>
        </div>
      </div>
      <div className='grid justify-center items-center text-yellow-200 border-2 border-yellow-200 p-3'>
       
        <div className='flex justify-center items-center p-3'>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <button onClick={DisplayData} className='flex border-white border-2 justify-center items-center p-3'>Display Fetched Data</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Apis;
