import React from 'react';
import { Link } from 'react-router-dom';
import AIArtificialIntelligenceSmartContractAuditVulnerabilityCodeMonitoringSecurityFREE from '../assets/AI-Artificial-Intelligence-Smart-Contract-Audit-Vulnerability-Code-Monitoring-Security-FREE.png';

function SmartContractAuditor() {
  return (
    <div className="screen w-auto justify-center items-center border-none animate-rise md:mt-[100px] md:pb-[50px] md:pt-[200px] bg-gradient-to-b from-black to-[#0b073f]">


<div className='flex justify-center items-center  md:p-0 md:mx-0 mx-5'>
          <div className="max-w-lg md:px-12 md:py-9 px-5 py-1 pt-5 bg-gradient-to-tr from-[#000000] to-[#001d25] border border-gray-200 rounded-3xl shadow transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-101 ">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-white">Smart Contract Auditor</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            I made this for my fellow blockchain devs, so you won't need to pay $10,000 to audit your Smart Contracts. Just copy and paste it into Smart Contract Auditor GPT and find any possible vulnerabilities it might have.
            <br /><br />
            *Requires a premium OpenAI account 
            </p>
            
            <div>
              <img src={AIArtificialIntelligenceSmartContractAuditVulnerabilityCodeMonitoringSecurityFREE} alt='AI Artificial Intelligence Smart Contract Audit Vulnerability Code Monitoring Security FREE' />
            </div>
            <Link to='https://chat.openai.com/g/g-l5TjvTkj2-smart-contract-auditor' target='_target' rel='noreferrer noopener'>

            <div className="flex w-auto justify-center items-center px-3 py-2 text-lg md:mt-12 md:mx-[25%] mt-12 font-medium text-center text-white border-white border-2 
            bg-gradient-to-tr from-[#000000] to-[#291348] 
            hover:bg-gradient-to-tr hover:from-[#291348] hover:to-[#000000] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    Try it now
        </div>
        </Link>
            <p>

            </p>
            <br />
            
          </div>
        </div>


    </div>
  )
}

export default SmartContractAuditor;