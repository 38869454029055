import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';


const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div id="Navbar" className='flex bg-gradient-to-tl from-[#000000] to-[#130432] justify-between items-center h-24 md:max-w-full md:mx-24 md:px-10 px-5 mx-auto text-white'>
      <Link to='/'><h1 className='w-full text-lg md:text-3xl font-bold text-[#00df9a] m-4'>Sam Samie</h1></Link>
      <ul className='hidden md:flex'>
        <Link to="/"><li className='p-4 hover:text-[#4df5e1]'>HOME</li></Link>
        <a href="#projects"><li className='p-4 hover:text-[#4df5e1] transition duration-1000'>Projects</li></a>
        <a href="https://docs.google.com/forms/d/1apr7iVPrfZBkHClt_emz9BvhUNo74EuztJBZxt9wP7c/viewform?edit_requested=true" target='_blank' rel='noopener noreferrer'><li className='p-4 mr-3 hover:text-[#4df5e1]'>Contact</li></a>
        <li><button onClick={"none"} type="button" className='flex bg-gradient-to-bl from-[#58098d] to-[#3d24ff] w-[110px] h-[30px] text-white justify-center items-center rounded-md my-0 text-sm font-medium mx-auto md:mx-0 mt-4'>
                          Connect Wallet
                        </button></li>
      </ul>
      <div onClick={handleNav} className='hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <Link to='/'><h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>Sam Samie</h1></Link>
        <li className='p-4'><a href='/'>HOME</a></li>
        <li className='p-4'><a href='#projects'>Projects</a></li>
        <li className='p-4'><a href="https://docs.google.com/forms/d/1apr7iVPrfZBkHClt_emz9BvhUNo74EuztJBZxt9wP7c/viewform?edit_requested=true" target='_blank' rel='noopener noreferrer'>Contact</a></li>
          <li><button onClick={"none"} type="button" className='flex bg-gradient-to-bl from-[#58098d] to-[#3d24ff] w-[110px] h-[30px] text-white justify-center items-center rounded-md my-0 text-sm font-medium mx-auto md:mx-0 mt-4'>
                          Connect Wallet
                        </button></li>
      </ul>
    </div>
  );
};

export default Navbar;
