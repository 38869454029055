import React from 'react';
// import Future_of_Tech_and_Trends_AI_Technology_Book_Sam_Samie from '../assets/Future_of_Tech_and_Trends_AI_Technology_Book_Sam_Samie.png';
// import Future_of_Tech_and_Trends_AI_Technology_Book_Sam_Samie_Hover from '../assets/Future_of_Tech_and_Trends_AI_Technology_Book_Sam_Samie_Hover.png';
import { Link } from 'react-router-dom';
import '../css/Cards1.css';
import Apple_Badge_Sam_Samie_Books_Get from '../assets/Apple_Badge_Sam_Samie_Books_Get.png';
import Amazon_Badge_Sam_Samie_Books_Get from '../assets/Amazon_Badge_Sam_Samie_Books_Get.png';
import Google_Badge_Sam_Samie_Books_Get from '../assets/Google_Badge_Sam_Samie_Books_Get.png';

function FutureOfTechAndTrendsBySamSamie() {
  // const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex w-auto border-none justify-center items-center md:mx-80 md:mt-72 md:mb-72 mt-32">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center px-[5%]'>
          <div className="justify-center items-center w-full p-0 bg-none border-noneshadow ">
            <div className='flex w-auto'>
            <video className="shadow-lg w-full border-2 rounded-[45px] overflow-hidden border-none" autoPlay loop muted>
          <source src="/Future_of_Tech_and_Trends_AI_Technology_Book_Author_Sam_Samie_vid_loop.mp4" type="video/mp4" /> {/* Referencing from public directory */}
          Your browser does not support the video tag.
        </video>
            </div>
          </div>
        </div>

        <div className='flex justify-center items-center md:p-0 p-[25px] animate-rise-r-to-l md:mx-0 md:mb-0 mb-36'>
          <div className="max-w-sm p-7 bg-gradient-to-tr from-[#001212] to-[#020202] border border-[#781c73] rounded-3xl shadow">
            <h3 className='text justify-center text-center font-bold bold text-3xl text-[#83e8ef] pb-8'>
              Future of Tech and Trends:
            </h3>
            <p className='text justify-center text-center text-1xl font-normal text-gray-400'>
              'Future of Tech and Trends: Exploring the Future of AI and Technology.' This comprehensive guide unravels the complexities of AI, cloud computing, edge computing, and blockchain, presenting their transformative impacts across multiple sectors.
            </p>




            <div className="flex w-auto justify-center items-center pt-1 text-sm mt-5 mx-[30%] font-medium text-center text-white border-gray-400 border-none">
            <Link to="https://play.google.com/store/books/details?id=CxAUEQAAQBAJ" target='_blank' rel='noopener noreferrer'>
            
              <div>
                <img  src={Google_Badge_Sam_Samie_Books_Get} alt='Future of Tech and Trends: Exploring the Future of AI and Technology Author Sam Samie' />
              </div>
              
            </Link>
            </div>




            <div className="flex w-auto justify-center items-center py-1 text-sm mt-2 mx-[30%] font-medium text-center text-white border-gray-400 border-none">
            <Link to="https://books.apple.com/us/book/future-of-tech-and-trends/id6557080346" target='_blank' rel='noopener noreferrer'>
            
              <div>
                <img  src={Apple_Badge_Sam_Samie_Books_Get} alt='Future of Tech and Trends: Exploring the Future of AI and Technology Author Sam Samie on Apple Books' />
              </div>
              
            </Link>
            </div>




            <div className="flex w-auto justify-center items-center py-1 text-sm mt-2 mx-[30%] font-medium text-center text-white border-gray-400 border-none">
            <Link to="https://www.amazon.com.au/Future-Tech-Trends-Exploring-Technology-ebook/dp/B0D9K76X3X/ref=mp_s_a_1_1?crid=MWWOXCE9MDUY&dib=eyJ2IjoiMSJ9.Gbv3ZNjsnGizYmvKcUafkE5UYIhUd9PLcDkvmLu14mFIDJV7RuWWCZWIOePN39NTleDS6lkHzZZKoDYIf4zytTPnTAn97vY2vROrSplOMPCmOL72cctZYIEd6pSdD9MbSjlkoLWI0FGPtlB3B-itzHjaas04BTPSB4bbH9PxNAFO-MCcj7Z0nTWWWdVlODcc4M-h7rON-17PtmR6L5Owsw.PAZo_Yc_0uObcVD8QeWPq8P-wdo8rOE7gMPEC0nyr0k&dib_tag=se&keywords=sam+samie&qid=1721912141&s=books&sprefix=sam+samie%2Caps%2C848&sr=1-1" target='_blank' rel='noopener noreferrer'>
            
              <div>
                <img  src={Amazon_Badge_Sam_Samie_Books_Get} alt='Future of Tech and Trends: Exploring the Future of AI and Technology Author Sam Samie' />
              </div>
              
            </Link>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default FutureOfTechAndTrendsBySamSamie;
