import React from 'react';

function AttackMap() {
  return (
    <div className='flex justify-center items-center sm:w-full '>
      <iframe 
        src="https://cybermap.kaspersky.com/en/widget/dynamic/dark" 
        width="99%" 
        height="800px" 
        style={{ border: 'none'}}
        sandbox="allow-scripts allow-same-origin"
        title="Bitdefender Attack Map"
       
        
    
      >
        Your browser does not support iframes.
      </iframe>
    </div>
  );
}

export default AttackMap;
