import React from 'react';
import SamSamieImage from '../assets/SamSamieImage.gif';
import { Link } from 'react-router-dom';
import '../css/SamSamieWiki.css';

function SamSamieWiki() {
  return (
    <div className="screen w-full justify-center items-center border-none my-64 wiki-background">
      <div className='grid md:grid-cols-2'>
      <div className='flex justify-center items-center md:p-4 p-[25px] animate-rise-r-to-l'>
          <div className="max-w-lg p-7 bg-gradient-to-r from-[#000000] to-[#000000] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="text-5xl font-bold tracking-tight text-gray-200">Biography</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            Sam Samie, an IT engineer with a Master’s Degree in Business Management, combines his technical expertise in JavaScript development and blockchain technology with a deep interest in economic principles and market investments.
            <br />
            <br />As the author of "She Wants Me to NFT," Sam provides valuable insights into Non-Fungible Tokens, highlighting their role in revolutionizing digital ownership and interactions. His dedication to exploring economic trends through a technological lens makes him a distinctive voice in the digital economy.
            </p>
            <br />
            <Link to="/Avatar">
              <div className="inline-flex items-center px-3 py-2 text-sm mb-7 font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Chat with my Avatar
              </div>
            </Link>
          </div>
        </div>
        <div className='md:flex hidden justify-center items-center md:px-[270px] p-[75px] animate-rise-l-to-r'>
          <img className='animate-none md:p-[0px] py-0 border-8 border-[#1b4b5b] rounded-full' src={SamSamieImage} alt="Joker"  />
        </div>
      </div>
    </div>
  );
}

export default SamSamieWiki;
