import React from 'react';
import Logo from '../assets/logo512.png'
import {
  FaArrowUp,
  FaGithubSquare,
  FaLinkedinIn,
  FaTiktok,
  FaTwitterSquare,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="flex w-full justify-center items-center md:px-[25px] px-[10px]">
    <div id="FooterA" className='md:max-w-[1440px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Sam Samie</h1>
            <p className='flex justify-between md:w-[75%] my-6'>Explore my books, ongoing projects, and the latest updates on AI, technology, and market trends. Sign up to become a member and stay informed.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
    <a href="https://au.linkedin.com/in/samsamie" target="_blank" rel="noopener noreferrer" ><FaLinkedinIn  size={30}/></a>
    <a href="https://github.com/SS-4" target="_blank" rel="noopener noreferrer" ><FaGithubSquare size={30}/></a>
    <a href="https://x.com/SamSamieReal" target="_blank" rel="noopener noreferrer" ><FaTwitterSquare size={30}/></a>
    <a href="https://www.tiktok.com/@samsamie.com?lang=en" target="_blank" rel="noopener noreferrer" ><FaTiktok size={30}/></a>
        </div>
        </div>

<div className='lg:col-span-2 flex justify-between mt-6'>
<div className='p-0 md:p-0'>
    <h6 className='md:pb-4 pb-2 font-medium text-gray-400 md:text-[16px] text-[14px]'>Resources</h6>
    <ul>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='./Apis' rel='noopener noreferrer' target='_blank'>API</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://github.com/SS-4' rel='noopener noreferrer' target='_blank'>GitHub</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='#' rel='noopener noreferrer' target='_blank'>Smart Contracts</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://www.google.com.au/search?tbo=p&tbm=bks&q=inauthor:%22Sam+Samie%22' rel='noopener noreferrer' target='_blank'>Google Books</Link></li>
      
    </ul>
</div>

<div className='p-0 md:p-0'>
<h6 className='md:pb-4 pb-2 font-medium text-gray-400 md:text-[16px] text-[14px]'>Communication</h6>
    <ul>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://docs.google.com/forms/d/e/1FAIpQLSdNFDXXK3_47MMgedEh1M_o1yQo7Ta0zsBNzgCX641zjw7VOg/viewform?usp=sf_link' rel='noopener noreferrer' target='_blank'>Contact</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://docs.google.com/forms/d/e/1FAIpQLSdNFDXXK3_47MMgedEh1M_o1yQo7Ta0zsBNzgCX641zjw7VOg/viewform?usp=sf_link' rel='noopener noreferrer' target='_blank'>Investor</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://docs.google.com/forms/d/e/1FAIpQLSdNFDXXK3_47MMgedEh1M_o1yQo7Ta0zsBNzgCX641zjw7VOg/viewform?usp=sf_link' rel='noopener noreferrer' target='_blank'>Devs</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://docs.google.com/forms/d/e/1FAIpQLSdNFDXXK3_47MMgedEh1M_o1yQo7Ta0zsBNzgCX641zjw7VOg/viewform?usp=sf_link' rel='noopener noreferrer' target='_blank'>Press</Link></li>
        
    </ul>
</div>

<div className='p-0 md:p-0'>
<h6 className='md:pb-4 pb-2 font-medium text-gray-400 md:text-[16px] text-[14px]'>Projects</h6>
    <ul>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://wordpress.org/plugins/code-monitor/#description' rel='noopener noreferrer' target='_blank'>Code Monitor</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://chat.openai.com/g/g-l5TjvTkj2-smart-contract-auditor' rel='noopener noreferrer' target='_blank'>Smart Contract Auditor</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to='https://github.com/SS-4/KeySpectra' rel='noopener noreferrer' target='_blank'>KeySpectra</Link></li>
        <li className='py-1 md:py-2 text-[10px] md:text-[16px]'><Link to="/Avatar">My AI Avatar</Link></li>
        
    </ul>
</div>
</div>
<div className='lg:col-span-3 flex justify-between'>
<div>
       <img className='flex w-[150px] h-[150px] animate-spin' src={Logo} alt="Sam Samie" />
    </div><div>
        <ul>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
        </ul>
    </div>
    <div className='mt-5'>
    <a href="#Navbar">    
        <FaArrowUp size={80}/>
            <p className='flex justify-center mt-3'>
                PAGE UP
            </p></a>
    
    </div>

</div>
</div>
    </div>
  )
}

export default Footer;
