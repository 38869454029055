import React from 'react';
import '../css/TitleCss.css'

const Title = () => {
  return (
    <div className='text-white md:my-24 md:pb-[0px] pb-[10px] Title-section-background '>
<div className='flex items-end justify-center mt-[10px]'>
</div>

      <div className='max-w-[800px] mt-[10px] w-full mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#00df9a] font-bold p-2'>
          
        </p>
    
        <div className='flex justify-center items-center'>
          

          
          
        </div>
        <div className='justify-center items-center pb-2 mt-4 text-4xl'>
         <h1 className='font-bold bg-gradient-to-r from-purple-800 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text md:text-4xl text-2xl mt-12 '>Sam Samie's Books</h1>
        </div>
        <p className='md:text-2xl text-1xl font-bold text-gray-500 md:p-1 mx-7'>All about technology, markets and the excitement to create the future!</p>
        <form action="https://docs.google.com/forms/d/1apr7iVPrfZBkHClt_emz9BvhUNo74EuztJBZxt9wP7c/viewform?edit_requested=true" target='_blank' rel='noopener noreferrer'>
        <button className='bg-gradient-to-bl from-[#4ab1f5] to-[#69e99c] w-[200px] rounded-md font-medium mt-[40px] my-1 mx-auto py-3 text-black'>Get Started</button></form>
      </div>
    </div>
  );
};

export default Title;
