// src/components/Statistics.jsx
import React from 'react';
import Statistic from './Statistic.jsx';

const Statistics = () => {
    return (
      <div className="bg-none text-white mb-24 md:mb-40 md:mx-24 mx-3 border-none md:mt-3 mt-3 overflow-hidden">
        <div className="md:grid md:grid-cols-3 md:mx-0 justify-center items-center space-x-0 md:border-[#171b1d] border-[#333333] border-2 rounded-[45px] overflow-hidden">
          <div className='flex items-center justify-center py-10 mb-5 md:py-15 md:px-10 bg-gradient-to-t from-[#000000] to-[#161616]'>
            <Statistic start={0} end={6738} label="Downloads on Google Books" />
          </div>
          <div className='flex items-center justify-center py-10 mb-5 md:py-15 md:px-10 bg-gradient-to-t from-[#000000] to-[#161616]'>
            <Statistic start={0} end={713} label="Code Monitor Installations" />
          </div>
          <div className='flex items-center justify-center py-10 mb-5 md:py-15 md:px-10 bg-gradient-to-t from-[#000000] to-[#161616]'>
            <Statistic start={0} end={27000} label="Total Book Downloads" />
          </div>
        </div>
      </div>
    );
  };
  
  export default Statistics;
