import React from 'react';
import { Link } from 'react-router-dom';
import ThankYouEmoji from '../assets/ThankYouEmoji.png';
import CircleCheck from '../assets/Circle-Check.png';
import ITSecurity from '../assets/ITSecurity.png';

function Card1() {
  return (
    <div className="screen w-full justify-center items-center border-none p-[25px] cards1-image-background">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center py-10 md:p-1  md:mx-0 mx-5'>
          <div className="md:h-[500px] max-w-lg p-7 bg-none border border-gray-200 rounded-3xl shadow transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-95">
            
            <div className='flex justify-start items-start w-auto h-auto my-0'>
            <img src={ITSecurity} alt='54' className='ml-0 mr-2 w-12'></img>
            <h2 className="mt-1 text-3xl font-bold tracking-tight text-white">KeySpectra (KeyLogger)</h2>
            </div>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            I made this powerful key-logger in JavaScript to see how I could stop such a sophisticated malicious application. To make it real I made Spectra too sophisticated to see how far it can go against me! Some of the features include:</p>
            
            
            <div className='flex justify-start items-start w-auto h-5 my-2 text-[#62fff7]'>
              <img src={CircleCheck} alt='circlecheck' className='mr-2 w-5'></img>
              <p>encryption</p>
            </div>
            
            <div className='flex justify-start items-start w-auto h-5 my-2 text-[#62fff7]'>
              <img src={CircleCheck} alt='circlecheck' className='mr-2 w-5'></img>
              <p>Hides if AV is active</p>
            </div>

            <div className='flex justify-start items-start w-auto h-5 my-2 text-[#62fff7]'>
              <img src={CircleCheck} alt='circlecheck' className='mr-2 w-5'></img>
              <p>Periodic report by E-Mail</p>
            </div>

            <div className='flex justify-start items-start w-auto h-5 my-2 text-[#62fff7]'>
              <img src={CircleCheck} alt='circlecheck' className='mr-2 w-5'></img>
              <p>Single .JS file</p>
            </div>


            <br />
            
              <div className='flex justify-start items-start w-auto h-5 my-2 text-[#fdff9e]'>
              <img src={ThankYouEmoji} alt='th' className='mr-2 w-5'></img>
              <p>For educational purposes only</p>
              </div>


              
<Link to="https://github.com/SS-4/KeySpectra" alt="KeySpectra KeyLogger By Sam Samie" target='_blank' rel='noreferrer noopener'>
              <div className="flex w-[120px] justify-center items-center h-11 my-14 font-medium text-center text-white text-lg border-[#4aeda4] border
            bg-gradient-to-tr from-[#02a40a] to-[#008728] 
            hover:bg-gradient-to-tr hover:from-[#008728] hover:to-[#02a40a] focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg">
    Learn more
        </div>
        </Link>

          </div>
          
        </div>






        
        <div className='md:flex hidden justify-center items-center'>
          <div className="flex md:h-[500px] p-10 border-none">
            <img src={ITSecurity} alt='IT Security'></img>
            
            
            
        </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
